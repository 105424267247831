import { Switch, withRouter, Route } from 'react-router';
import { Helmet } from "react-helmet";

import Home from './views/Home';
import Minerva from './views/Minerva';
import FDB from './views/FdbMobler';
import Quicken from './views/Quicken';
import ItStillHurts from './views/ItStillHurts';
import AsiaA from './views/AsiaA';

const App = () => {
  return (
  	<>
    	<Helmet>
        <title>Michelle Bushoy</title>
    	</Helmet>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/minerva" component={Minerva} />
        <Route exact path="/fdbmobler" component={FDB} />
        <Route exact path="/quicken" component={Quicken} />
        <Route exact path="/stillhurts" component={ItStillHurts} />
        <Route exact path="/asiaa" component={AsiaA} /> 
      </Switch> 
    </>
  );
}

export default App;
