import Nav from '../../components/Nav'
import Footer from '../../components/Footer';
import Recommended from '../../components/Recommended';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectTechnology from '../../components/ProjectTechnology';
import ScrollToTop from '../../components/ScrollToTop';

import { WorkWrapper, Section, Divider, WorkContent, ImageDiv, BodyTextLink } from '../../index.styles'

import Colors from '../../assets/asiaa_colors.png';
import Mockups from '../../assets/asiaa_mockups.png';
import { 
  BodyText,
  SubHeader,
  Image
} from "./AsiaA.styles"

const AsiaA = () => {
  const tech = ['Adobe XD', 'PHP', 'HTML', 'CSS', 'JavaScript']

  return (
    <>
      <ScrollToTop />
      <Nav />
      <WorkWrapper>
        <ProjectHeader 
          project="ASIA A"
          projectDate="sep 2019 - feb 2020"
          description="ASIA A is a short film following Marquise, a recent spinal cord injury patient struggling to reconcile his sense of self-worth with his new reality as a paraplegic. The film was directed by Andrew Reid. "
          role="web design and development"
          tech={tech}
        />
        <Divider />
        <WorkContent>
          <Section>
            <SubHeader>Context</SubHeader>
            <BodyText>I worked very closely with Andrew Reid, the director of ASIA A, to work on design and development for the website for his short film.</BodyText>
            <BodyText>ASIA A delves deeply into the reality of what it’s like to suffer injury and ultimately become paralyzed. The name of this short film refers to the classification given to Marquise, the film's protagonist, based on a system of tests developed by the American Spinal Injury Association (ASIA). These tests — based on how much sensation someone can feel at multiple points on the body, as well as the motor function — define and describe the severity of a patient’s spinal cord injury. The film in itself is emotional, striking and powerful.</BodyText>
          </Section>
          <Section>
            <SubHeader>The Problem</SubHeader>
            <BodyText><b>How can we design and develop <i>the right</i> website to promote and portray ASIA A?</b></BodyText>
            <BodyText>The ASIA A team was looking for a site that could be a platform for education, while also showing off the film and it’s accolades. The ASIA A team wanted a site that felt as bold and powerful as his film, while also being simple, usable, accessible and easily digestible.</BodyText>
            <BodyText>We worked together on ideation and design, going back and forth on what the vision of this site would look and feel like.</BodyText>
          </Section>
          <Section>
            <SubHeader>The Solution</SubHeader>
            <BodyText>The ASIA A team needed a site that felt bold, but also powerful and serious to mirror the emotions from the film itself.</BodyText> 
            <BodyText>The team already had a logo created, as well as stills from the film that could be used on-site. Many of the images provided had hues of blue, green and yellow so I wanted to use that to ideate on a color scheme and identity of the site.</BodyText>
            <ImageDiv>
              <Image src={Colors} />
            </ImageDiv>
            <BodyText>After taking a look at the potential color scheme I wanted to use, I decided to stick to a monochromatic theme for the site to allow the images to appear bolder and have the colors in them to speak for themselves. As such, I opted for a black background and white text, using large, bold type (in san-serif font <b>Lato</b>) to further exhibit a level of boldness and power that we see from the film.  I strictly wanted to stay away from any playful or cute-sy site design, and instead opted for darkness and mystery. Similarly, I found that the black background allowed room to elevate the colors from the images.</BodyText>
            <ImageDiv>
              <Image src={Mockups} />
            </ImageDiv>
            <BodyText>While the black background helped me achieve my design goals, there were still questions of accessibility and making sure that the text was easy to read. I chose a clear white text color for optimal contrast, and made sure to use bold text and large font-sizes for maximum readability.</BodyText>
            <BodyText>It was also important to both me and the ASIA A team to keep the website simple. The goal of the site was the provide information about those within the parapalegic community and the ASIA A short film, and the team felt that any additional complexity would take away from that. I respected their wish and stuck with a very simple site design.</BodyText>
          </Section>
          <Section>
            <SubHeader>Development</SubHeader>
            <BodyText>This site was developed in <b>PHP</b>, <b>HTML</b>, <b>CSS</b> and <b>JavaScript</b>. I was the sole developer on this project, and worked off of my own mockups, which you can see above. </BodyText>
            <BodyText>You can find the site live at <BodyTextLink href="http://whatisasiaa.com/" target="_blank">www.whatisasiaa.com</BodyTextLink>.</BodyText>
          </Section>
        </WorkContent>
      </WorkWrapper>
      <Recommended left={{to: 'minerva', name: 'Minerva Analytics'}} right={{to: 'quicken', name: 'Quicken Loans'}}/>
      <Footer />
    </>
  );
}

export default AsiaA;
