import {useState, useEffect} from "react";

import Headshot from '../../assets/headshot.png';
import BubbleHeadshot4 from '../../assets/bubble_headshot_9.png';

import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import WorkList from '../../components/WorkList';

import { BodyText, Divider } from '../../index.styles';


import { 
  HomeWrapper, 
  Header, 
  WorkChoice, 
  LeftList, 
  RightImage,
  ContentSection,
  IntroSection,
  TextContainer,
  HeadshotImage,
  IntroWrapper,
  LeftBlob,
  WorkContainer,
  LeftBottomBlob,
  HeadshotImageMobile,
  DisclaimerText,
  IntroImage
} from "./Home.styles"

const Home = ({image}) => {
  const [img, setImg] = useState('');
  const [text, setText] = useState('ballpoint pens');
  const textWords = ['dogs', 'coffee', 'beer'];

  const onItemClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <>

      <Nav home />
      <HomeWrapper>
          <IntroWrapper>

            <IntroSection>
              <TextContainer>
                <Header> Welcome! </Header>
                <BodyText><b>Software engineer at Minerva Analytics.</b> I'm a front end engineer with a passion for finding the intersection between code and design driven by empathy and a user-centric perspective.</BodyText>
                <BodyText>In my free time, I love fostering dogs, learning to skateboard (looking for help here) and making to-do lists on the "Notes" app on my iPhone.</BodyText>
              </TextContainer>
            </IntroSection>
            <IntroImage>
              <HeadshotImageMobile src={Headshot} />
              <HeadshotImage src={BubbleHeadshot4} />
            </IntroImage>
          </IntroWrapper>

        <Divider />

        <WorkContainer>
          <ContentSection>
            <WorkList />
          </ContentSection>
        </WorkContainer>

      </HomeWrapper>
      <Footer home />


    </>
  );
}

export default Home;
