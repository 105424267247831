import styled from "styled-components";
import { SubHeader as BasicSubHeader, BodyText as BasicBodyText } from '../../index.styles'
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { MOBILE, TABLET, LARGE, X_LARGE } from '../../index.styles'

export const FooterContainer = styled.div`
  padding: 9rem 0 2rem 0;
  margin-bottom: 2rem;
  width: 100%;
  position: absolute;
  left: 0;

  @media only screen and (max-width: ${LARGE}) {
    padding-top: 5rem;
  }

  @media only screen and (max-width: ${MOBILE}) {
    padding-top: 3rem;
  }
`;

export const FooterContent = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  margin: auto;

  @media only screen and (max-width: ${X_LARGE}) {
    flex-direction: column;
    margin: auto;
    width: 85%;
  }
`;

export const LeftHeader = styled(BasicSubHeader)`
  color: black;
`;

export const LeftSection = styled.div`
  flex-direction: column;
  max-width: 400px;
  text-align: left;

  @media only screen and (max-width: ${LARGE}) {
    max-width: 600px;
  }

  @media only screen and (max-width: ${TABLET}) {
    max-width: unset;
  }
`;

export const RightSection = styled.div`
  flex-direction: column;
  margin-left: auto;
  @media only screen and (max-width: ${LARGE}) {
    margin-left: unset;
  }  
`;

export const Link = styled.a`
  font-family: 'Poppins', sans-serif;
  color: black;
  cursor: pointer;
  text-decoration: none;
`;

export const Linkedin = styled(FaLinkedinIn)`
  fill: black;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
  cursor: pointer;
`;

export const Github = styled(FaGithub)`
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
  cursor: pointer;
  fill: black;
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
`;
