import styled from "styled-components";
import { MOBILE, TABLET, LARGE, X_LARGE } from '../../index.styles'

export const ProjectHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #e0d7ce;

`;

export const ProjectHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: ${LARGE}) {
    flex-direction: column;
  }
`;

export const ProjectName = styled.h1`
  color: #1C4A47;
  font-family: "Forum";
  font-size: 65px;
  margin: 0 5rem 0rem 0;
  opacity: 0.3;

  @media only screen and (max-width: ${LARGE}) {
    margin: 0 0 1rem 0
  }

  @media only screen and (max-width: ${TABLET}) {
    font-size: 55px;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 44px;
  }
`;

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media only screen and (max-width: ${X_LARGE}) {
    max-width: 550px;
  }

  @media only screen and (max-width: ${LARGE}) {
    max-width: 500px;
  }
`;

export const ProjectDate = styled.h3`
  color: black;
  font-family: "Poppins";
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 0.7rem;

  span {
    opacity: 0.5;
    font-weight: 500;
  }

  @media only screen and (max-width: ${LARGE}) {
    display: none;
  } 

`;

export const MobileProjectDate = styled.h3`
  display: none;

  @media only screen and (max-width: ${LARGE}) {
    display: block;
    color: black;
    font-family: "Poppins";
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 0.7rem;
  }

  @media only screen and (max-width: ${MOBILE}) {
    line-height: 16px;
  }
`;

export const ProjectDescription = styled.p`
  color: black;
  font-family: "Poppins";
  line-height: 22px;
  letter-spacing: 0.5px;
`;

export const Role = styled.p`
  margin-top: 0;
  font-weight: 500;
  color: black;
  font-family: "Poppins";
  line-height: 22px;
  letter-spacing: 0.5px;
  opacity: 0.6;

  @media only screen and (max-width: ${MOBILE}) {
    margin: 0;
  } 

`;

export const Divider = styled.hr`
  margin-top: 3rem;
  border: 2px solid #d1c6b8;
  background-color: #d1c6b8;
  max-width: 83%;
  display: block;
`;
