import styled from "styled-components";

export const MOBILE = `36rem`;
export const TABLET = `48rem`;
export const LARGE = `62rem`;
export const X_LARGE = `75rem`;

export const SubHeader = styled.h1`
  color: #1C4A47;
  font-family: "Forum";

  @media only screen and (max-width: ${TABLET}) {
    font-size: 1.7rem;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 1.4rem;
  }
`;

export const BodyText = styled.p`
  color: black;
  font-family: "Poppins";
  line-height: 22px;
  letter-spacing: 0.4px;
  line-height: 1.6;
  font-size: 18px;

  @media only screen and (max-width: ${TABLET}) {
    font-size: 16px;
    line-height: 1.75rem;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 15px;
  }
`;

export const BodyTextLink = styled.a`
  color: black;
  font-family: "Poppins";
  line-height: 22px;
  letter-spacing: 0.4px;
  line-height: 1.6;
  font-size: 18px;
  text-decoration: underline;
  font-weight: 800;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET}) {
    font-size: 16px;
    line-height: 1.75rem;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 15px;
  }
`;

export const Divider = styled.hr`
  margin: 3rem 0;
  border: 1px solid #d1c6b8;
  background-color: #d1c6b8;
  width: 100%;
`;

export const WorkWrapper = styled.div`
  margin: 0 auto;
  width: 60%;
  padding: 6rem 2em 1rem 2rem;
	position:relative;

  @media only screen and (max-width: ${LARGE}) {
  	width: 70%;
   	padding: 3rem 0;
  }

  @media only screen and (max-width: ${TABLET}) {
  	width: 75%;
   	padding: 3rem 0;
  }

  @media only screen and (max-width: ${MOBILE}) {
  	width: 80%;
  	padding: 3rem 0;
  }
`;

export const Section = styled.div`
  margin-bottom: 5rem;

  @media only screen and (max-width: ${MOBILE}) {
  	margin-bottom: 2.5rem;
  }
`;

export const ImageDiv = styled.div`
  margin: 3rem 0;
  justify-content: center;

  @media only screen and (max-width: ${MOBILE}) {
  	margin: 1.5rem 0;
  }
`;

export const WorkContent = styled.div`
  text-align: left;
  margin-top: 6rem;

   @media only screen and (max-width: ${TABLET}) {
    margin-top: 2rem;
  } 
  @media only screen and (max-width: ${MOBILE}) {
    margin: 0;
  } 
`;