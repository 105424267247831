import React, { useState } from 'react';

import { 
  Arrow,
} from "./ScrollToTop.styles"

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 450){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 450){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
    <Arrow onClick={scrollTop} style={{display: showScroll ? 'block' : 'none'}}/>
  );
}

export default ScrollToTop;
