import { NavContainer, LeftText, RightText, RightLinkText } from "./Nav.styles"
import { Link, Element, Events, animateScroll } from 'react-scroll'

const Nav = ({home}) => {
  const scrollDown = () => {
    animateScroll.scrollTo(700);
  }

  return (
    <NavContainer>
    	<LeftText to="/">Michelle Bushoy</LeftText>
    	<div>
    	{home ?
	    	<RightText onClick={scrollDown}>
	    		Skip to work  &#8595;
	    	</RightText>
      : <RightLinkText to='/'>
          Back to home
        </RightLinkText>
      }
    	</div>
    </NavContainer>
  );
}

export default Nav;
