import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Recommended from '../../components/Recommended';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectTechnology from '../../components/ProjectTechnology';
import ScrollToTop from '../../components/ScrollToTop';

import StillHurts from '../../assets/hurts.png';
import MindMap from '../../assets/mindmap.png';

import { 
  SubHeader,
  BodyText,
  PosterImg,
  PosterDiv,
  Image,
  HorizImage
} from "./ItStillHurts.styles"

import { WorkWrapper, Section, Divider, ImageDiv, WorkContent, BodyTextLink } from '../../index.styles';


const ItStillHurts = () => {
  const tech = ['Adobe Photoshop', 'Adobe Illustrator']

  return (
    <>
      <ScrollToTop />
      <Nav />
      <WorkWrapper>
        <ProjectHeader 
          project={`"It Still Hurts" Campaign`}
          projectDate="2017"
          description="It Still Hurts is a campaign meant to destigmatize and speak out about mental health. The posters are part of a public service announcement serving to highlight the pain that mental health may induce, though silent and seldom talked about."
          role="graphic design"
          tech={tech}
        />
        <Divider />
        <WorkContent>

          <Section>
            <SubHeader>Context</SubHeader>
            <BodyText>As part of a Digital Media Studies course at the University of Rochester, I was tasked to design a set of posters for a local organization that I was passionate about. As an advocate for mental health awareness and advocacy, I chose <BodyTextLink href="https://www.namiroc.org/" target="_blank">NAMI Rochester</BodyTextLink>. NAMI (National Alliance for Mental Health) is a non-profit organization which provides education, support and advocacy for those who are struggling with their mental health or living with mental illness.</BodyText>
            <BodyText>I had first discovered this organization when I volunteered with <BodyTextLink href="https://samaritanshope.org/" target="_blank">Samaritans Suicide Prevention Hotline</BodyTextLink> in Boston, Massachusetts from 2012 - 2015. At the time, I was a phone line volunteer and would spend time each week speaking to callers who needed someone to talk to. Time and time again, I heard people who felt nervous and even fearful to talk about what they were going through.</BodyText>
            <BodyText>Those few years only further inspired to speak out about mental health - it’s so prevalent, so “human”, and yet so seldom talked about. NAMI Health, a national organization with locations in many cities around the United States, aims to provide that space to those who are struggling and allow them to feel seen and heard.</BodyText>
          </Section>

          <Section>
            <SubHeader>Process</SubHeader>
            <BodyText>To start my ideation process, I created a mind map surrounding "Mental Health". The goal of this mind map was to try to get outside of my own head in my brainstorming process and attempt to abstract the idea of mental health in one way or another. As you can see below, I highlighted words that stuck out to me, like <b>"physical"</b>, <b>"bandaid"</b>, <b>"facade"</b> and <b>"monochromatic"</b>.</BodyText>
            <ImageDiv>
              <Image src={MindMap} />
            </ImageDiv>
            <BodyText>The words "physical" and "bandaid" especially stuck. Mental health can feel so deeply painful, and yet so difficult to verbalize. On the other hand, it's much easier to articulate physical pain - a paper cut, sore arm after a flu shot, stubbed toe, and the list goes on. This inspired me to contrast mental health to physical health as the center of my posters. </BodyText>
            <BodyText>Within the posters, I chose to use bold sans-serif type and a green background. Mental health is represented by a green ribbon, so I wanted to make sure to integrate that into my designs. The bold sans-serif type is meant to show boldness and make a clear statement. </BodyText>
          </Section>

          <Section>
            <SubHeader>Final Posters</SubHeader>
            <BodyText>The final posters aim to juxtapose how we consider physical and mental health. Although mental health is hard to see or even talk about, it <i>does</i> hurt. Mentall illness is not just "a phase" or a bad day. The more we can talk about mental health, the closer we can get to ending the stigma. </BodyText>
            <PosterDiv>
              <PosterImg src={StillHurts} />
            </PosterDiv>
            <BodyText>To learn more about NAMI, visit <BodyTextLink href="https://www.nami.org/">www.nami.org</BodyTextLink>. If you or a loved one is struggling with mental illness and does not know where to turn, please refer to <BodyTextLink href="https://www.nami.org/Get-Involved/Pledge-to-Be-StigmaFree/StigmaFree-Company/Resources" target="_blank">NAMI's mental health resources</BodyTextLink>.</BodyText>
          </Section>


        </WorkContent>
      </WorkWrapper>
      <Recommended left={{to: 'quicken', name: 'Quicken Loans'}} right={{to: 'fdbmobler', name: 'FDB Møbler'}}/>
      <Footer />
    </>
  );
}

export default ItStillHurts;
