import styled from "styled-components";
import { 
  SubHeader as BasicSubHeader, 
  BodyText as BasicBodyText, 
  MOBILE,
  TABLET,
  LARGE
} from '../../index.styles'

export const SubHeader = styled(BasicSubHeader)`
  margin-bottom: 2rem;
`;

export const BodyText = styled(BasicBodyText)`
  margin: 1.5rem 0;
`;

export const Header = styled.h1`
  font-size: 45px;
  font-family: "Poppins";
`;

export const Persona = styled.img`
  width: 50%;
  height: auto;
  margin-right: 1rem;
  object-fit: cover;

  @media only screen and (max-width: ${LARGE}) {
    width: 80%;
    margin: 0 0 1rem 0;
  }

  @media only screen and (max-width: ${MOBILE}) {
    width: 100%;
  }
`;

export const PersonaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -5rem;

  @media only screen and (max-width: ${LARGE}) {
    flex-direction: column;
    margin: 0;
  }
`;

export const HorizImage = styled.img`
  width: 100%;

  display: flex;
  align-items: center;
  object-fit: cover;

  @media only screen and (max-width: ${MOBILE}) {
    display: none;
  }

`;

export const HorizImageMobile = styled.img`
  display: none;

  @media only screen and (max-width: ${MOBILE}) {
    display: block;
    width: 100%;

    display: flex;
    align-items: center;
    object-fit: cover;
  }
`;

export const SideBySideImg = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${MOBILE}) {
    flex-direction: column;
  }
`;

export const SideBySide = styled.img`
  width: 65%;
  margin-right: 1rem;

  @media only screen and (max-width: ${LARGE}) {
    margin-right: 0;
  }

  @media only screen and (max-width: ${MOBILE}) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;