import styled from "styled-components";
import { SubHeader as BasicSubHeader, BodyText as BasicBodyText } from '../../index.styles'

export const SubHeader = styled(BasicSubHeader)`
  margin-bottom: 2rem;
`;

export const BodyText = styled(BasicBodyText)`
  margin: 1.5rem 0;
`;

export const Image = styled.img`
  margin: 2rem auto;
  width: 80%;
  display: flex;
  align-items: center;
`;
