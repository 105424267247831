import styled from "styled-components";

import { MOBILE, TABLET, LARGE, X_LARGE } from '../../index.styles'

export const HomeWrapper = styled.div`
  padding: 6rem 0;
  width: 80%;
  margin: 0 auto;

  @media only screen and (max-width: ${X_LARGE}) {
    width: 85%;
  }
   
  @media only screen and (max-width: ${LARGE}) {
    padding: 1rem 0;
  }

`;

export const Header = styled.h1`
  color: #1C4A47;
  font-family: "Forum", sans-serif;
  font-size: 75px;
  font-weight: 700;
  margin: 0 0 0.8rem 0;

  @media only screen and (max-width: ${LARGE}) {
    margin-bottom 2rem;
    font-size: 70px;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 65px;
  }
`;

export const TextContainer = styled.div`
  max-width: 600px;

  @media only screen and (max-width: 1450px) {
    max-width: 500px;
  }

  @media only screen and (max-width: ${LARGE}) {
    max-width: 650px;
  }
`;


export const Divider = styled.hr`
  margin-top: 3rem;
  margin-bottom: 3rem;
  border: 1px solid #d1c6b8;
  background-color: #d1c6b8;
`;

export const ContentSection = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  z-index: 5;

  @media only screen and (max-width: ${MOBILE}) {
    margin-top: 0;
  }
`;

export const IntroSection = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;

  @media only screen and (max-width: ${MOBILE}) {
    margin-bottom: 1.7rem;
  }
`;

export const IntroImage = styled.div`
  position: absolute;

  @media only screen and (max-width: 1200px) {
    position: unset;
    display: flex;
    align-items: center;
    width: 50%;
  }

  @media only screen and (max-width: ${LARGE}) {
    width: 100%;
  }
`;

export const HeadshotImage = styled.img`
  object-fit: cover;
  width: 100%;
  z-index: -1;
  position: relative;
  top: -22rem;

  @media only screen and (max-width: 1550px) {
    top: -16rem;  
  }

  @media only screen and (max-width: 1300px) {
    top: -15rem;  
    width: 110%;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }

`;


export const HeadshotImageMobile = styled.img`
  display: none;

  @media only screen and (max-width: 1200px) {
    width: 90%;
    display: block;
    margin-left: 4rem;
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: ${LARGE}) {
    width: 80%;
    margin: 1rem 0 1.5rem 0;
  }

  @media only screen and (max-width: ${MOBILE}) {
    width: 100%;
  }
`;

export const WorkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7rem;
  margin-top: 1.7rem;

  @media only screen and (max-width: ${LARGE}) {
    flex-direction: column;
    margin-bottom: 2rem;
  }
`;

