import ProjectTechnology from '../ProjectTechnology'
import { Divider } from '../../index.styles'
import { BodyText } from '../../index.styles'

import { 
  ProjectHeaderContainer, 
  ProjectHeaderWrapper, 
  ProjectName, 
  ProjectDate, 
  ProjectContent,
  ProjectDescription,
  Role,
  MobileProjectDate
} from "./ProjectHeader.styles"

const ProjectHeader = ({project, projectDate, description, role, tech}) => {
  return (
    <ProjectHeaderContainer>
      <ProjectHeaderWrapper>
        <ProjectName>{project}</ProjectName>
        <ProjectContent>
          <ProjectDate>{project} &#160;&#160; <span>{projectDate}</span></ProjectDate>
          <MobileProjectDate>{projectDate}</MobileProjectDate>
          <Role>{role}</Role>
          <BodyText>{description}</BodyText>
          <ProjectTechnology technology={tech} />
        </ProjectContent>
      </ProjectHeaderWrapper>
    </ProjectHeaderContainer>

  );
}

export default ProjectHeader;
