import styled from "styled-components";
import { Link } from "react-router-dom";
import { MOBILE } from '../../index.styles'

export const NavContainer = styled.div`
  min-height: 6rem;
  color: #215459;
  font-family: "Poppins";
  padding: 1.5rem 3rem 0 3rem;
  display: flex;

  @media only screen and (max-width: ${MOBILE}) {
    min-height: 3rem;
    padding: 2rem 0 0 0;
    width: 85%;
    margin: auto;
  }
`;

export const LeftText = styled(Link)`
  text-decoration: none;
  color: #215459;
  cursor: pointer;
  font-size: 18px;
  margin-right: auto;
  display: flex;
  margin-top: 1.5rem;

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 16px;
    margin: 0 auto 0 0;
  }
`;

export const RightText = styled.p`
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
  background: linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat;
  transition: 1.8s background-size;
  background-position: left bottom;
  margin-left: auto;
  &:hover{
    background-size: 100% .1em;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 16px;
    margin: 0;
  }

`;


export const RightLinkText = styled(Link)`
  color: #215459;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
  text-decoration: none;
  background: linear-gradient(currentColor, currentColor) bottom / 0 .1em no-repeat;
  transition: 1.8s background-size;
  background-position: left bottom;
  &:hover{
    background-size: 100% .1em;
  }

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 16px;
    margin: 0;
  }

`;

