import styled from "styled-components";
import { SubHeader as BasicSubHeader, BodyText as BasicBodyText, MOBILE } from '../../index.styles'

export const SubHeader = styled(BasicSubHeader)`
  margin-bottom: 2rem;
`;

export const BodyText = styled(BasicBodyText)`
  margin: 1.5rem 0;
`;


export const ProcessImgHorizontal = styled.img`
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto;
  object-fit: cover;
`;

export const FinalImage = styled.img`
  margin: 2rem auto;
  width: 105%;
  display: flex;
  align-items: center;
  object-fit: cover;

  @media only screen and (max-width: ${MOBILE}) {
    width: 100%;
    margin: auto;
  } 
`;
