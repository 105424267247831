import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Recommended from '../../components/Recommended';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectTechnology from '../../components/ProjectTechnology';
import ScrollToTop from '../../components/ScrollToTop';

import Sites from '../../assets/sites.png';
import Sites2 from '../../assets/sites2.png';
import Foodisle1 from '../../assets/foodisle1.png';
import Foodisle2 from '../../assets/foodisle2.png';
import Persona1 from '../../assets/persona1.png';
import Persona3 from '../../assets/persona3.png';

import { 
  BodyText,
  SubHeader,
  Header,
  Persona,
  HorizImage,
  HorizImageMobile,
  SideBySideImg,
  SideBySide,
  PersonaContainer
} from "./Minerva.styles"

import { WorkWrapper, Section, Divider, ImageDiv, WorkContent, BodyTextLink } from '../../index.styles';


const Minerva = () => {
  const tech = ['React', 'Redux', 'Styled Components', 'Django', 'Kubernetes', 'Docker']

  return (
    <>
      <ScrollToTop />
      <Nav />
      <WorkWrapper>
        <ProjectHeader 
          project="Minerva Analytics"
          projectDate="sep 2019 - present"
          description="Minerva Analytics is a media company which launches and scales a number of digital brands built on the back of 5+ years of accumulated market data and user research."
          role="full stack development"
          tech={tech}
        />
        <Divider />
        <WorkContent>
          <Header>Pantheon</Header>
          <Section>
            <SubHeader>The Problem</SubHeader>
            <BodyText><b>How can we speed up development time for our media brand websites?</b></BodyText>
            <BodyText>To help with Minerva’s mission to create snackable content for our users, Minerva wants to build up as many sites and features for our media brands as quickly as possible.</BodyText>
            <BodyText>In order to do so, we needed to speed up development and design time by creating more standardization between our media sites. Our goal was to define more out of the box solutions for our sites instead of having to develop each one in a silo.</BodyText>
            <BodyText>Our first step was thorough user research to understand how we could streamline those processes. My team scheduled 2 weeks worth of user interviews with designers, fellow engineers, analysts, product managers and executives within our company. From these interviews, we were able to nail into themes that we could distill onto our main user personas. I personally interviewed nearly half of our intervieewes, and collaborated with the team to create the personas. </BodyText>
            <ImageDiv>
              <PersonaContainer>
                <Persona src={Persona1} />
                <Persona src={Persona3} />
              </PersonaContainer>
            </ImageDiv>

          </Section>
          <Section>
            <SubHeader>The Solution</SubHeader>
            <BodyText>Pantheon is an npm package which provides out of the box features and themable elements that could easily be added to any of our sites. I worked to simplify all of our flows - from allowing users to subscribe to our newsletters through the website, to allowing our product team to create blog posts on-site.</BodyText>
          </Section>
          <Section>
            <SubHeader>Development</SubHeader>
            <BodyText>Pantheon is written in <b>React</b> and <b>Redux</b>, which I heavily leaned on in order to make development time outside of our standard features even faster. The team did an audit of all of our current sites, includes ones dating back to the Minerva’s earliest media brands in 2016, to find our most utilized flows. In turn, I was able to standardize multiple Redux models and write many custom React hooks. For example, I wrote a custom React hook which connects to <BodyTextLink href="https://matomo.org/" target="_blank">Matomo</BodyTextLink> to allow for an incredibly easy setup process for A/B tests. Now, we’re able to set up A/B tests within minutes, as opposed to hours.</BodyText>
            <BodyText>I was one of three engineers on the Pantheon team, where we developed some of our most used components that are integrated in nearly each of our front end codebases. <b>To date, I am the highest contributor to our pantheon repo</b> and was lucky enough to have a heavy hand in the initial ideating, user research, user testing, development and finally, maintenance.</BodyText>
          </Section>

          <Header>Media Brands</Header>
          <Section>
            <SubHeader>Context</SubHeader>
            <BodyText>Minerva has a suite of media brands within a variety of markets - food, gaming, history, astrology, and more. Each of Minerva’s media brands have both in-email and on-site experiences for their users, aiming to provide engaging and user driven content within each of the markets.</BodyText>
            <ImageDiv>
              <HorizImage src={Sites} />
              <HorizImageMobile src={Sites2} />
            </ImageDiv>
          </Section>

          <Section>
            <SubHeader>Starter Site</SubHeader>
            <BodyText>I worked on a team of 2 engineers and a designer to speed up the time it takes to develop a brand new website for any incoming media brand. We named this project “starter site”, as it would be the starting repository for any new site. </BodyText>
            <BodyText>Every time a developer was working to develop a new website from scratch, they realized that they were intalling the same NPM packages, following a consistently similar site flow and required the same set of data from our APIs. The same work was being done constantly, and wasting valuable engineering time that could be used elsewhere. Similarly, the company was just not meeting the mark for how quickly we wanted to get these sites out of the door. One stipulation, though, was that at this point in the company timeline, Minerva still had a great deal of variation in the type of content featured on each site. Our project needed a solution that made site development quick and easy, but also flexible and scalable.</BodyText>
            <BodyText>We created an internal tool that acted as a shell for any of our new websites. I pre-installed all the necessary NPM packages, imported React components directly out of Pantheon (as described above) to ensure standardization, had pre-made components which hit the necessary endpoints, and had tests written for each piece of the puzzle. Using the starter site, creating a site went from <b>2 weeks</b> to a <b>single day</b>.</BodyText>
            <BodyText>In the past quarter, the starter site has been used 6 times to develop 6 brand new websites for our company.</BodyText>
          </Section>

          <Section>
            <SubHeader>Media Site Development</SubHeader>
            <BodyText>I have had the opportunity to work on nearly all of the websites within Minerva’s suite of media brands. To date, I am the highest contributor in over half of Minerva’s front end code repositories. I especially worked heavily on <BodyTextLink href="https://foodisle.com" target="_blank">Foodisle</BodyTextLink> and <BodyTextLink href="https://starguidehoroscopes.com" target="_blank">Starguide</BodyTextLink> - adding features like content categorization, blogging functionality, and an authorization flow for users. I also developed <BodyTextLink href="https://backthenhistory.com" target="_blank">Back Then History</BodyTextLink>, <BodyTextLink href="https://keepitreeldaily.com" target="_blank">Keep It Reel</BodyTextLink> and <BodyTextLink href="https://financianews.com" target="_blank">Financia</BodyTextLink> from scratch. </BodyText>
          </Section>

          <Section>
            <SubHeader>Foodisle: Media Site Development Example</SubHeader>
            <BodyText>For 6 months, I was the sole developer on the Foodisle team. Foodisle, one of Minerva’s highest-engaged with media brands, creates relationships with food bloggers and showcases their recipes to help the any at-home cook find doable, but delicious, recipes. In my time on the foodisle team, I worked on a plethora of features which are now live on the site.</BodyText>
            <BodyText>Through Foodisle’s Facebook page, we were seeing that our engagers wanted a way to interact with the site itself. The site was designed to showcase a list of random food blogger’s recipes per day, which users could then share amongst each other through a variety of social media platforms. However, that wasn’t enough; our users wanted more experiences on-site. As such, I worked on creating an entire user flow - from authenticating the user through Facebook & Google sign in, to having and maintaining a user profile, and being able to save any recipes to the user profile. I worked with a team of data analysts and a designer to get this feature into production, which is still live and highly utilized today.</BodyText>
            
            <ImageDiv>
              <SideBySideImg>
                <SideBySide src={Foodisle1} />
                <SideBySide src={Foodisle2} />
              </SideBySideImg>
            </ImageDiv>

            <BodyText>Aside from user authentication, I worked on many other features on the site - a design refresh for the recipe page, adding categories to the site, refining the recipe filters section and adding a shop page. You can find these features, amongst awesome recipes, at <BodyTextLink href="https://foodisle.com" target="_blank">www.foodisle.com</BodyTextLink>.</BodyText>
          </Section>

        </WorkContent>
      </WorkWrapper>

      <Recommended left={{to: 'fdbmobler', name: 'FDB Møbler'}} right={{to: 'asiaa', name: 'ASIA A'}}/>
      <Footer />
    </>
  );
}

export default Minerva;
