import styled from "styled-components";
import { MOBILE } from '../../index.styles'

export const TechnologyContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`;

export const TechnologyHeader = styled.p`
  font-weight: 500;
  color: black;
  font-family: "Poppins";
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-bottom: 0;
  margin-right: 1rem;
  font-size: 15px;

  @media only screen and (max-width: ${MOBILE}) {
    margin-right: 1rem;
  } 
`;

