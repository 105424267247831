import Nav from '../../components/Nav'
import ProjectHeader from '../../components/ProjectHeader'
import ProjectTechnology from '../../components/ProjectTechnology'
import Footer from '../../components/Footer';
import Recommended from '../../components/Recommended';
import ScrollToTop from '../../components/ScrollToTop';

import Process1 from '../../assets/process1.png';
import Process2 from '../../assets/process2.png';
import Process3 from '../../assets/process3.png';
import FdbBoard from '../../assets/fdbboard.png';

import { 
  ProcessImgHorizontal, 
  FinalImage, 
  SubHeader,
  BodyText,
} from "./FdbMobler.styles";

import { Divider, WorkWrapper, Section, ImageDiv, WorkContent } from '../../index.styles'

const FdbMobler = () => {
  const tech = ['Adobe Photoshop', 'Adobe Illustrator', 'Adobe InDesign']
  const description = `FDB Møbler, a Danish furniture company established in 1942, perfectly exemplifies Danish design - minimal and functionalist. Today, FDB Mobler stores represent tradition and timelessness.`

  return (
    <>
      <ScrollToTop />
      <Nav />
      <WorkWrapper>
        <ProjectHeader 
          project="FDB Møbler"
          projectDate="2018"
          description={description}
          role="brand identity"
          tech={tech}
        />
        <Divider />
        <WorkContent>
          <Section>
            <SubHeader>Context</SubHeader>
            <BodyText>Danish design is renound for it's minimalist, though functionalist, utility. FDB Møbler perfectly exemplifies Danish design, as their chairs have been a staple for decades in Danish homes. After closing its factory in 1980, FDB Mobler relocated a number of old classics in 2013; at that moment, an old classic was rediscovered. Today, FDB Møbler stores exist throughout Denmark and exhibit qualities of close family values together with creating a democratic community. To many Danish people, FDB Mobler means tradition and timelessness.</BodyText>
            <BodyText>During my semester abroad in Copenhagen Denmark, we were tasked to recreate the brand identity for FDB Møbler. FDB Møbler represents the old and new. It's young parents shopping for FDB Møbler chairs today, reminiscing on the ones they had in their family kitchen years ago. It's a chair representing every day life for every generation.</BodyText>
          </Section>

          <Section>
            <SubHeader>Logo Design Process</SubHeader>
            <BodyText>My process began by looking at the chairs and seeing how I could take inspiration from their forms. I noticed that there was a lot of <b>linearity</b> in the chairs themselves. The most notable FDB Møbler chairs were ones that included thin linear panels.</BodyText>
            <ProcessImgHorizontal src={Process1} />
            <BodyText>Additionally, I found inspirations in the letters "F", "D" and "B", finding that these letters also stem from lines. When writing each of these letters, you always start by first making a line. From here, I looked at how the linear shapes of these letters could fit themselves into the linear panels of FDB Møbler chairs.</BodyText>
            <ImageDiv>
              <ProcessImgHorizontal src={Process2} />
            </ImageDiv>
            <BodyText>I took a closer look at my ideation process, and found that putting aspects of a physical chair into the logo might be too on the nose. I maintained my idea of a linear theme, though manifested it into a different plan. I thought about using the lines to convey an idea of <b>"building and breaking"</b>, as you would when setting up furniture. I played around with incorporating that theme into the letters "F", "D" and "B".</BodyText>
          </Section>

          <Section>
            <SubHeader>Brand Identity</SubHeader>
            <ImageDiv>
              <ProcessImgHorizontal src={Process3} />
            </ImageDiv>
            <BodyText>I digitally mocked up my sketches. I looked to shirts, business cards and bags as a way to further visualize the brand as i continued to develop it.</BodyText>
          </Section>

          <Divider />
          <FinalImage src={FdbBoard} />
        </WorkContent>
      </WorkWrapper>
      <Recommended left={{to: 'minerva', name: 'Minerva Analytics'}} right={{to: 'quicken', name: 'Quicken Loans'}}/>
      <Footer />
    </>
  );
}

export default FdbMobler;
