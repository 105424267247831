import { 
  RecommendedContainer,
  RecommendedWrapper,
  LeftItem,
  RightItem,
  RecommendedItem,
  RecommendedText,
  Left,
  Right
} from "./Recommended.styles"

const Recommended = ({left, right}) => {
  const onItemClick = () => {
    window.scrollTo(0, 0);
  }
  return (
    <RecommendedContainer>
      <RecommendedText>Related Work</RecommendedText>

      <RecommendedWrapper>
        <LeftItem>
          <RecommendedItem to={`${left.to}`} onClick={onItemClick}><Left />{left.name}</RecommendedItem>
        </LeftItem>
        <RightItem>
          <RecommendedItem to={`${right.to}`} onClick={onItemClick}>{right.name}<Right /></RecommendedItem>
        </RightItem>
      </RecommendedWrapper>
    </RecommendedContainer>
  );
}

export default Recommended;
