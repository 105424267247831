import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

import { 
  FooterContainer, 
  FooterContent, 
  LeftHeader,
  LeftSection,
  RightSection,
  Linkedin,
  Link,
  Github,
  Icons
} from "./Footer.styles";
import Resume from '../../assets/resume.pdf';
import { BodyText } from '../../index.styles';

const Footer = ({home}) => {

  return (
    <FooterContainer home={home}>
      <FooterContent>

        <LeftSection>
          <LeftHeader> Thanks for stopping by! </LeftHeader>
          <BodyText> If you’d like to see more of what I’m up to, check out these links. I’m always happy to chat!</BodyText>
        </LeftSection>

        <RightSection>
          <BodyText><Link href="mailto:michelle.bushoy@gmail.com"><b>michelle.bushoy@gmail.com</b></Link></BodyText>
          <BodyText>Resume <Link href={Resume} target='_blank'><u>here</u></Link></BodyText>
          <Icons>
            <a href='https://www.linkedin.com/in/michelle-bushoy-725730137/' target='_blank'><Linkedin /></a>
          </Icons>
        </RightSection>

      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
