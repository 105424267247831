import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import Recommended from '../../components/Recommended';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectTechnology from '../../components/ProjectTechnology';
import ScrollToTop from '../../components/ScrollToTop';

import { WorkWrapper, Section, Divider, WorkContent, BodyTextLink } from '../../index.styles';

import { 
  SubHeader,
  BodyText,
} from "./Quicken.styles"

const Quicken = () => {
  const tech = ['HTML', 'SCSS', 'JavaScript', 'PHP', 'Wordpress Development']

  return (
    <>
      <ScrollToTop />
      <Nav />
      <WorkWrapper>
        <ProjectHeader 
          project="Quicken Loans"
          projectDate="may 2019 - aug 2018"
          description="Quicken loans is a leading mortgage lending company with a emphasis on creating good technology to improve the lives of their clients"
          role="web development"
          tech={tech}
        />
        <Divider />
        <WorkContent>
          <Section>
            <SubHeader>Context</SubHeader>
            <BodyText>During Summer 2018, I interned at Quicken Loans as a Web Developer Intern. I worked on an internal web development team of 6 engineers who primarily support internal training teams, mortgage banking teams, and human resource teams. While on this team, I leveraged <b>WordPress as the CMS</b> and MySQL for database connections. The development stack consisted of <b>PHP, JavaScript, HTML, Sass and Foundation</b> as a front-end framework.</BodyText>
          </Section>
          <Section>
            <SubHeader>The Problem</SubHeader>
            <BodyText><b>How do we create a tool to help mortgage bankers find information as quickly as possible?</b></BodyText>
            <BodyText>Mortgage bankers at Quicken Loans are always looking to find information as quickly as possible when working with clients. Always on the phone with a client while trying to grab information from their dusty manuals, they often struggle to find information quickly when a tricky question is asked. As such, these bankers need a simple tool that they can leverage to find information quickly.</BodyText>
          </Section>
          <Section>
            <SubHeader>The Solution</SubHeader>
            <BodyText>During my internship at Quicken Loans, I lead engineering efforts on an internal tool to help mortgage bankers easily find information when working with clients. Working with a product owner to complete our task at hand, we began our process by speaking to the bankers, then ideating a solution, and finally designing and developing it. On this project, I handled both the design and development. Ultimately, the final product utilized an advanced search feature, where our bankers could quickly search for a word. Alternatively, we made sure to display information as cleanly as possible incase our users had any need to sift through the information manually based on categories or keywords. <b>This tool is now in production</b> and utilized by mortgage bankers at Quicken Loans.</BodyText>
          </Section>
          <Section>
            <SubHeader>Processes</SubHeader>
            <BodyText>While at Quicken Loans, I worked in an <b>agile environment</b>, which followed typical daily ceremonies such as a daily stand up (scrum) meeting, sprint planning, retro and refinements. I also was trained in the <BodyTextLink href="https://www.scaledagileframework.com/" target="_blank">Scaled Agile Framework (SAFe)</BodyTextLink>. </BodyText>
            <BodyText>Unfortunately, I cannot include screenshots of my work of Quicken Loan’s tooling because the project is under NDA. Please reach out to learn more. </BodyText>
          </Section>
        </WorkContent>
      </WorkWrapper>
      <Recommended left={{to: 'asiaa', name: 'Asia A'}} right={{to: 'stillhurts', name: 'It Still Hurts'}}/>
      <Footer />
    </>
  );
}

export default Quicken;
