import styled from "styled-components";
import { SubHeader as BasicSubHeader, BodyText as BasicBodyText, MOBILE } from '../../index.styles'

export const SubHeader = styled(BasicSubHeader)`
  margin-bottom: 2rem;
`;

export const BodyText = styled(BasicBodyText)`
  margin: 1.5rem 0;
`;

export const PosterImg = styled.img`
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  object-fit: cover;

  @media only screen and (max-width: ${MOBILE}) {
    width: 100%;
  }
`;

export const PosterDiv = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  width: 68%;
  display: flex;
  align-items: center;
  margin: auto;
  object-fit: cover;
`;

export const HorizImage = styled.img`
  width: 20%;
  display: flex;
  align-items: center;
  margin: auto;
  object-fit: cover;
`;
