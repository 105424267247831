import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { MOBILE, TABLET } from '../../index.styles'

export const RecommendedContainer = styled.div`
  padding: 3rem 0;
  border-top: 1px solid #d2c7bc;
  border-bottom: 1px solid #d2c7bc;
  width: 80%;
  margin: auto
`;

export const RecommendedWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LeftItem = styled.div`
  flex-direction: column;

`;

export const RightItem = styled.div`
  flex-direction: column;
  margin-left: auto;
`;

export const RecommendedItem = styled(Link)`
  color: #1C4A47;
  font-family: "Forum";
  font-size: 35px;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET}) {
    font-size: 25px;
  } 

  @media only screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  } 
`;

export const RecommendedText = styled.p`
  color: #363431;
  font-family: "Poppins";
  line-height: 1.55em;
  font-size: 16px;
  font-weight: 500;
  margin: auto 1.4rem 1rem auto;
  opacity: 0.5;
  cursor: pointer;
`;

export const Left = styled(FaAngleLeft)`
  fill: #1c4a47;
  width: 2rem;
  height: 2rem;

  margin-right: 1rem;
  margin-bottom: -0.4rem;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET}) {
    width: 1.5rem;
    height: 1.5rem;
  } 

  @media only screen and (max-width: ${MOBILE}) {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
    margin-bottom: -0.3rem;
  } 
`;

export const Right = styled(FaAngleRight)`
  fill: #1c4a47;
  width: 2rem;
  height: 2rem;

  margin-left: 1rem;
  margin-bottom: -0.4rem;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET}) {
    width: 1.5rem;
    height: 1.5rem;
  } 

  @media only screen and (max-width: ${MOBILE}) {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.4rem;
    margin-bottom: -0.3rem;

  } 
`;

