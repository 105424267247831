import { Link } from "react-router-dom";
import styled from "styled-components";
import { MOBILE, TABLET, LARGE, X_LARGE } from '../../index.styles'

export const DisclaimerText = styled.p`
  color: #363431;
  font-family: "Poppins";
  line-height: 1.55em;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1.7rem;
  font-style: italic;
  opacity: 0.5;

  @media only screen and (max-width: ${LARGE}) {
    display: none;
  }
`;

export const LeftList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const RightImageContainer = styled.div`
  margin-left: 15rem;
  margin-top: 7rem;

  @media only screen and (max-width: ${X_LARGE}) {
  	margin-left: 12rem;
  }

  @media only screen and (max-width: ${LARGE}) {
    display: none;
  }
`;

export const RightImage = styled.img`
  height: 350px;
  opacity: 0.9;
`;


export const WorkChoice = styled(Link)`
  color: #215459;
  font-family: "Forum", sans-serif;
  font-size: 44px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 3.5rem;
  font-weight: 700;

   @media only screen and (max-width: ${MOBILE}) {
  	font-size: 30px;

  }
`;