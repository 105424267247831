import { TechnologyContainer, TechnologyHeader, TechnologyItem } from "./ProjectTechnology.styles"

const ProjectTechnology = ({technology}) => {
  return (
    <TechnologyContainer>
        {technology.map(value => 
          <TechnologyHeader>{value}</TechnologyHeader>
        )}
    </TechnologyContainer>
  );
}

export default ProjectTechnology;
