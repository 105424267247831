import React, {useState} from "react";

import Asia from '../../assets/asia.png';
import Minerva7 from '../../assets/minerva7.png';
import FDB4 from '../../assets/fdb4.png';
import StillHurts from '../../assets/stillhurts.png';
import Quicken from '../../assets/ql.png';

import { 
  LeftList,
  DisclaimerText,
  WorkChoice,
  RightImage,
  RightImageContainer
} from "./WorkList.styles"

const WorkList = () => {
  const [img, setImg] = useState('');


  const onItemClick = () => {
    window.scrollTo(0, 0);
  }
  return (
  	<>
      <LeftList>
        <DisclaimerText>Hover to preview; click to learn more.</DisclaimerText>

        <WorkChoice
          onClick={() => onItemClick()}
          onMouseEnter={e => {setImg(Minerva7)}}
          onMouseOut={e => setImg('')}
          to='/minerva'
        >
          Minerva Analytics
        </WorkChoice>

        <WorkChoice
          onMouseEnter={e => {setImg(Asia)}}
          onMouseOut={e => setImg('')}
          to='/asiaa'
          onClick={() => onItemClick()}
        >
          ASIA A
        </WorkChoice>

        <WorkChoice
          to='/quicken'
          onMouseEnter={e => {setImg(Quicken)}}
          onMouseOut={e => setImg('')}
          onClick={() => onItemClick()}
        >
          Quicken Loans
        </WorkChoice>

        <WorkChoice 
          to="/stillhurts"
          onMouseEnter={e => {setImg(StillHurts)}}
          onMouseOut={e => setImg('')}
          onClick={() => onItemClick()}
        >
          "It Still Hurts" Campaign
        </WorkChoice>

        <WorkChoice 
          to='/fdbmobler'
          onMouseEnter={e => {setImg(FDB4)}}
          onMouseOut={e => setImg('')}
          onClick={() => onItemClick()}
        >
          FDB Møbler
        </WorkChoice>

      </LeftList>
      <RightImageContainer>
        <RightImage src={img}/>
      </RightImageContainer> 
  	</>
  );
}

export default WorkList;
