import styled from "styled-components";
import { FaArrowUp } from 'react-icons/fa';
import { MOBILE, LARGE } from '../../index.styles'

export const Arrow = styled(FaArrowUp)`
  position: fixed; 
  width: 100%;
  bottom: 1.5rem;
  left: 45%;
  height: 40px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.4;

  &:hover{
    opacity: 1;
    animation: roll 3s infinite;
    transform: rotate(30deg);
  }

  @media only screen and (max-width: ${LARGE}) {
    height: 30px;
  } 

  @media only screen and (max-width: ${MOBILE}) {
    height: 20px;
  } 


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes roll {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;
